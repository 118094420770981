import React from "react";
import {Image, Typography} from "antd";

const {Title} = Typography;

export default function Index() {
    return (
        <>
            <Typography>
                <Title>葛朗台(Grandet) V0.2-Beta</Title>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Image src={"GRANDET.svg"} alt="1" preview={false} width={800} height={600}/>
                </div>
            </Typography>
        </>
    );
}
